'use client';

import { Navigation, Pagination } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';

import Image from 'next/image';
import { useRouter } from 'next/navigation';

// eslint-disable-next-line import/order
import { Button, Container, Stack, Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import EastIcon from '@mui/icons-material/East';

import { FlexBox } from 'libs/components/Flex/flex-box';
import BlogCard from 'libs/components/blog-card';
import NextNavigationButton from 'libs/components/slider-btns/next-btn';
import PrevNavigationButton from 'libs/components/slider-btns/prev-btn';
import { Blog } from 'libs/dt-types/response-types/blog';
import { themeColors } from 'theme/theme-colors';
import {
  BlogsCardContainer,
  BlogTitleContainer,
  ExploreButtonContainer,
  StyleSwipper,
} from './style';

const BlogSection = ({ blogData }: { blogData: Blog[] }) => {
  const router = useRouter();

  return (
    <Container maxWidth="lg">
      <FlexBox
        gap={'1rem'}
        sx={{
          paddingY: '2rem',
        }}
      >
        <BlogTitleContainer>
          <Stack
            gap={'1rem'}
            width={'100%'}
            alignSelf={'center'}
            padding={'1.2rem'}
          >
            <Typography color={'#6E5BE4'}>Trending</Typography>
            <Typography
              variant="h2"
              fontSize={{ md: '1.5rem', xs: '1.2rem' }}
              maxWidth={{ xs: '100%', sm: '9rem' }}
              textTransform={'capitalize'}
            >
              See what’s next
            </Typography>
            <Typography
              color={themeColors.dark}
              fontSize={{ md: '1.2rem', xs: '0.9rem' }}
            >
              News, events, and insights into the ways Design template is making
              work faster & easier.
            </Typography>
          </Stack>
          <ExploreButtonContainer>
            <Image
              src={`${process.env.CONTENT_BASE_URL}/blog-mobile-img.webp`}
              alt="blog-img"
              loading="lazy"
              width={0}
              height={0}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: 'auto',
              }}
            />
            <Button
              sx={{
                minHeight: '2rem',
              }}
              variant="outlined"
              onClick={() => router.push(`${process.env.BLOG_URL}`)}
            >
              Explore Blogs
            </Button>
          </ExploreButtonContainer>
        </BlogTitleContainer>
        <BlogsCardContainer>
          <Typography
            sx={{
              color: themeColors.primary.main,
              marginLeft: 'auto',
              display: 'inline-block',
              verticalAlign: 'middle',
              cursor: 'pointer',
            }}
            onClick={() => router.push(`${process.env.BLOG_URL}`)}
          >
            See more{' '}
            <EastIcon
              sx={{
                verticalAlign: 'middle',
              }}
            />
          </Typography>
          <StyleSwipper
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            style={{
              overflow: 'hidden',
              maxHeight: '20rem',
              borderRadius: '0.8rem',
            }}
            breakpoints={{
              1200: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              800: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              350: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
          >
            <PrevNavigationButton count={blogData.length} />
            <NextNavigationButton />

            {blogData?.map((blog: any) => (
              <SwiperSlide
                style={{
                  display: 'flex',
                  gap: '2.5rem',
                  justifyContent: 'center',
                  padding: '0.2rem',
                }}
                key={blog.id}
              >
                <BlogCard blog={blog} key={blog.id} />
              </SwiperSlide>
            ))}
          </StyleSwipper>
        </BlogsCardContainer>
      </FlexBox>
    </Container>
  );
};

export default BlogSection;

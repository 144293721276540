import React from 'react';

import { Box } from '@mui/material';

import { FlexRowCenter } from 'libs/components/flex-box';

interface Banner3Props {
  children?: React.ReactNode;
}

const Banner3 = ({ children }: Banner3Props) => {
  return (
    <Box
      sx={{
        width: '100%',
        background: '#FFFDFC',
      }}
    >
      <FlexRowCenter
        alignItems={'center'}
        justifyContent={'center'}
        height={'100%'}
        width={'100%'}
      >
        {children}
      </FlexRowCenter>
    </Box>
  );
};

export default Banner3;

import { Stack } from '@mui/material';

import FAQ from 'libs/components/faq/FAQ';

const Banner5 = () => {
  return (
    <Stack
      spacing={1}
      justifyContent={'center'}
      alignItems={'center'}
      paddingY={'2%'}
    >
      <FAQ />
    </Stack>
  );
};

export default Banner5;

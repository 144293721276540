import { useSwiper } from 'swiper/react';

import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import { themeColors } from 'theme/theme-colors';

const NextNavigationButton = () => {
  const swiper = useSwiper();

  const goToNextSlide = () => {
    if (swiper.isEnd) {
      swiper.slideTo(0);
    } else {
      swiper.slideNext();
    }
  };

  return (
    <>
      <ArrowCircleRightOutlinedIcon
        sx={{
          color: '#6E5BE4',
          position: 'absolute',
          cursor: 'pointer',
          overflow: 'hidden',
          zIndex: 1000,
          right: -2,
          background: '#fff',
          borderRadius: '50px',
          top: '40%',
          '&:hover': {
            color: themeColors?.primary[500],
          },
        }}
        fontSize="large"
        onClick={goToNextSlide}
      />
    </>
  );
};

export default NextNavigationButton;

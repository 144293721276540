'use client';

import { DesktopImage, MobileImage } from './style';

const SharkTankBanner = () => {
  return (
    <a
      href={'https://youtu.be/8QV8nhXJEXw?si=TCfu7g80eUf_cyhQ'}
      target="_blank"
      style={{
        width: '100%',
        height: '100%',
        background: 'transparent',
      }} rel="noreferrer"
    >
      <DesktopImage
        src={`${process.env.CONTENT_BASE_URL}/Shark_Tank_Banner_250.webp`}
        alt="Featured on Shark Tank Image"
        width={300}
        height={100}
      />
      <MobileImage
        src={`${process.env.CONTENT_BASE_URL}/Shark_Tank_Banner.webp`}
        alt="Featured on Shark Tank Image"
        width={833}
        height={250}
      />
    </a>
  );
};

export default SharkTankBanner;

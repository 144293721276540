import { memo } from 'react';

import Link from 'next/link';

import { styled, Typography } from '@mui/material';

const StyledImageContainer = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'image',
})<{ image: string }>(({ image, theme }) => ({
  position: 'relative',
  height: '7rem',
  width: '7rem',
  aspectRatio: 1,
  objectFit: 'cover',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  paddingBottom: '4%',
  zIndex: 2,
  background: `url(${image})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  [theme.breakpoints.down(600)]: {
    width: '5rem',
    height: '5rem',
  },
  borderRadius: '6%',

  '&:before': {
    content: '""',
    zIndex: -1,
    width: '100%',
    height: '35%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    background:
      'linear-gradient(0deg, rgba(1, 1, 1, 0.8) 30%, rgba(0, 0, 0, 0.00) 101.33%)',
    borderRadius: '6%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.3s',
    opacity: 0.8,
  },
  '&:hover': {
    '&:before': {},
    cursor: 'pointer',
  },
}));

const StyledImageBox = ({
  image,
  tagName,
  source,
}: {
  image: string;
  tagName: string;
  source: string;
}) => {
  return (
    <StyledImageContainer href={source} image={image} prefetch={false}>
      <Typography
        role="button"
        fontWeight="bold"
        fontSize={{ md: '0.8rem', xs: '0.6rem' }}
        color="white"
        id="tag"
      >
        {tagName}
      </Typography>
    </StyledImageContainer>
  );
};

export default memo(StyledImageBox);

import { Swiper } from 'swiper/react';

import { Box, styled } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    width: 400,
  },
  [theme.breakpoints.down('lg')]: {
    width: 'calc(35% - 20px)',
    height: 'calc(35% - 10px)',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StyleWeddingSwipper = styled(Swiper)(({ theme }) => ({
  [theme.breakpoints.up(0)]: {
    width: '20rem',
  },
  [theme.breakpoints.up(424)]: {
    width: '30rem',
  },
  [theme.breakpoints.up(599)]: {
    width: '42rem',
  },
  [theme.breakpoints.up(799)]: {
    width: '45rem',
  },
  [theme.breakpoints.up(999)]: {
    width: '60rem',
  },
  [theme.breakpoints.up(1199)]: {
    width: '74rem',
  },
  [theme.breakpoints.up(1249)]: {
    width: '76rem',
  },
  '.swiper-button-next::after': {
    display: 'none',
  },
  '.swiper-button-prev::after': {
    display: 'none',
  },
}));

'use client';
import React from 'react';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { FlexRowCenter } from 'libs/components/flex-box';
interface FAQCollapseProps {
  Question: string;
  Answer: string;
  width?: string;
}

const FAQCollapse = ({
  Question,
  Answer,
  width = '60dvw',
}: FAQCollapseProps) => {
  const [open, setOpen] = React.useState(false);
  const [mystyle, setMyStyle] = React.useState('0deg');

  const handleClick = () => {
    setOpen(!open);

    setMyStyle(open === true ? '0deg' : '90deg');
  };

  return (
    <FlexRowCenter>
      <Box
        width={{
          sm: width,
          xs: '70vw',
        }}
      >
        <ListItemButton
          onClick={handleClick}
          sx={{
            borderRadius: '0.25rem',
            background: '#FFFDFC',
            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.16)',
            diplay: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ListItemText primary={Question} sx={{ position: 'relative' }} />
          <ArrowRightIcon
            color="primary"
            style={{ transform: `rotate(${mystyle})` }}
          />
        </ListItemButton>

        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{ backgroundColor: '#ebe9e9' }}
        >
          <List component="div" disablePadding>
            <ListItemButton>
              <ListItemText primary={Answer} />
            </ListItemButton>
          </List>
        </Collapse>
      </Box>
    </FlexRowCenter>
  );
};

export default FAQCollapse;

'use client';

import 'swiper/css';

import Image from 'next/image';
import 'swiper/css/autoplay';

import { Box } from '@mui/material';

import { FlexRowCenter } from 'libs/components/flex-box';

interface ImageData {
  src: string;
  alt: string;
}

interface InfiniteHorizontalScrollBarProps {
  images: ImageData[];
}

const InfiniteHorizontalScrollBar = ({
  images,
}: InfiniteHorizontalScrollBarProps) => {
  return (
    <FlexRowCenter>
      <Box
        display="flex"
        flexWrap={'wrap'}
        width={{ md: '80%', sm: '100%' }}
        flexDirection="row"
        justifyContent={'center'}
        alignContent={'center'}
        gap={'0.5rem'}
      >
        {images?.map((imageData) => (
          <Box
            key={imageData.src}
            position={'relative'}
            width={{ md: '14rem', sm: '9rem', xs: '7rem' }}
            height={{ md: '8rem', sm: '4rem', xs: '2rem' }}
            display="flex"
            flexWrap={'wrap'}
          >
            <Image
              src={imageData.src}
              alt={imageData.alt}
              height={0}
              width={0}
              style={{
                mixBlendMode: 'multiply',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              loading="lazy"
            />
          </Box>
        ))}
      </Box>
    </FlexRowCenter>
  );
};

export default InfiniteHorizontalScrollBar;

import createSvgIcon from '@mui/material/utils/createSvgIcon';

const StarIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M6.52447 0.963524C6.67415 0.502868 7.32585 0.50287 7.47553 0.963525L8.68386 4.68237C8.75079 4.88838 8.94277 5.02786 9.15938 5.02786H13.0696C13.554 5.02786 13.7554 5.64767 13.3635 5.93237L10.2001 8.23075C10.0248 8.35807 9.95149 8.58375 10.0184 8.78976L11.2268 12.5086C11.3764 12.9693 10.8492 13.3523 10.4573 13.0676L7.29389 10.7693C7.11865 10.6419 6.88135 10.6419 6.70611 10.7693L3.54267 13.0676C3.15081 13.3523 2.62357 12.9693 2.77325 12.5086L3.98157 8.78976C4.04851 8.58375 3.97518 8.35807 3.79994 8.23075L0.636495 5.93237C0.244639 5.64767 0.446028 5.02786 0.93039 5.02786H4.84062C5.05723 5.02786 5.24921 4.88838 5.31614 4.68237L6.52447 0.963524Z"
      fill="url(#paint0_linear_901_4221)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_901_4221"
        x1="7"
        y1="-0.5"
        x2="7"
        y2="15.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD401" />
        <stop offset="1" stopColor="#ECC400" />
      </linearGradient>
    </defs>
  </svg>,
  'StarIcon'
);

export default StarIcon;

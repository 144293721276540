import { Swiper } from 'swiper/react';

import { Box, Stack, styled } from '@mui/material';

import { FlexBox } from 'libs/components/flex-box';

export const BlogTitleContainer = styled(FlexBox)(({ theme }) => ({
  width: '100%',
  background: '#F9F8FF',
  justifyContent: 'center',
  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: '0.5rem',
  [theme.breakpoints.down(575)]: {
    justifyContent: 'space-around',
  },
}));

export const StyleSwipper = styled(Swiper)(({ theme }) => ({
  width: '50rem',
  [theme.breakpoints.down(1200)]: {
    width: '45rem',
  },
  [theme.breakpoints.down(1100)]: {
    width: '35rem',
  },
  [theme.breakpoints.down(900)]: {
    width: '30rem',
  },
  [theme.breakpoints.down(800)]: {
    width: '20rem',
  },
  [theme.breakpoints.down(700)]: {
    width: '15rem',
  },
  '.swiper-button-next::after': {
    display: 'none',
  },
  '.swiper-button-prev::after': {
    display: 'none',
  },
}));

export const ExploreButtonContainer = styled(Box)(({ theme }) => ({
  marginY: 'auto',
  width: '100%',
  textAlign: 'center',
  display: 'none',
  [theme.breakpoints.down(575)]: {
    display: 'inline-block',
  },
}));

export const BlogsCardContainer = styled(Stack)(({ theme }) => ({
  gap: '1rem',
  paddingX: '1rem',
  paddingBottom: '1rem',
  position: 'relative',
  [theme.breakpoints.down(575)]: {
    display: 'none',
  },
}));
